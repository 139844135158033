import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import EasySlider from 'vue-easy-slider'
import { functions } from './plugins/functions'

import { i18n } from '@/plugins/i18n'

Vue.use(EasySlider)

Vue.config.productionTip = false
Vue.prototype.$imgUrl = functions.imgUrl
Vue.prototype.$firstBold = functions.firstBold

const basePathsMap = {
	pl: {
		projects: 'projekty',
		study: 'nauka',
		about: 'o-mnie',
		contact: 'kontakt'
	},
	en: {
		projects: 'projects',
		study: 'study',
		about: 'about',
		contact: 'contact'
	}
}

const projectSlugsMap = {
	pl: {
		architektura: 'architektura',
		wnetrza: 'wnetrza',
		wystawy: 'wystawy'
	},
	en: {
		architektura: 'architecture',
		wnetrza: 'interiors',
		wystawy: 'exhibitions'
	}
}

const getPolishSlug = (currentSlug, currentLocale) => {
	if (currentLocale === 'pl') return currentSlug

	for (const [plSlug] of Object.entries(projectSlugsMap.pl)) {
		if (projectSlugsMap[currentLocale][plSlug] === currentSlug) {
			return plSlug
		}
	}
	return currentSlug
}

Vue.prototype.$getPolishSlug = function(currentSlug) {
	return getPolishSlug(currentSlug, i18n.locale)
}

Vue.prototype.$localePath = function(key, params = '') {
	const basePath = basePathsMap[i18n.locale][key]

	if (!params) {
		return `/${basePath}`
	}

	// Jeśli params zawiera slug projektu, tłumaczymy go
	const paramsArray = params.split('/')
	if (paramsArray[0] in projectSlugsMap.pl) {
		paramsArray[0] = projectSlugsMap[i18n.locale][paramsArray[0]]
	}

	return `/${basePath}/${paramsArray.join('/')}`
}

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
