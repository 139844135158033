export const YouTubePopUp = (href) => {
	let split_c = ''
	let split_n = ''

	if (href.match(/(youtube.com)/)) {
		split_c = 'v='
		split_n = 1
	}

	if (href.match(/(youtu.be)/) || href.match(/(vimeo.com\/)+[0-9]/)) {
		split_c = '/'
		split_n = 3
	}

	if (href.match(/(vimeo.com\/)+[a-zA-Z]/)) {
		split_c = '/'
		split_n = 5
	}

	const getYouTubeVideoID = href.split(split_c)[split_n]
	const cleanVideoID = getYouTubeVideoID.replace(/(&)+(.*)/, '')
	let videoEmbedLink = ''

	if (href.match(/(youtu.be)/) || href.match(/(youtube.com)/)) {
		videoEmbedLink =
			'https://www.youtube.com/embed/' + cleanVideoID + '?autoplay=1'
	}

	if (
		href.match(/(vimeo.com\/)+[0-9]/) ||
		href.match(/(vimeo.com\/)+[a-zA-Z]/)
	) {
		videoEmbedLink =
			'https://player.vimeo.com/video/' + cleanVideoID + '?autoplay=1'
	}
	if (href === 'https://vimeo.com/250004714') {
		videoEmbedLink =
			'https://player.vimeo.com/video/' + cleanVideoID + '?autoplay=1&muted=1'
	}
	const div = document.createElement('div')
	div.classList.add('YouTubePopUp-Wrap')
	div.classList.add('YouTubePopUp-animation')
	div.innerHTML =
		'<div class="YouTubePopUp-Content"><span class="YouTubePopUp-Close"></span><iframe id="player" src="' +
		videoEmbedLink +
		'&volume=0&vol=0&mute=1&setVolume=0&api=1&player_id=player" allowfullscreen></iframe></div>'
	document.body.append(div)
	if (
		document
			.querySelector('.YouTubePopUp-Wrap')
			.classList.contains('YouTubePopUp-animation')
	) {
		setTimeout(() => {
			document
				.querySelector('.YouTubePopUp-Wrap')
				.classList.remove('YouTubePopUp-animation')
		}, 600)
	}

	document.querySelector('.YouTubePopUp-Wrap').addEventListener('click', () => {
		document
			.querySelector('.YouTubePopUp-Wrap')
			.classList.add('YouTubePopUp-Hide')
		if (document.querySelector('.YouTubePopUp-Wrap')) {
			setTimeout(() => {
				document.body.removeChild(document.querySelector('.YouTubePopUp-Wrap'))
			}, 500)
		}
	})
}

document.addEventListener('keyup', (e) => {
	if (e.keyCode == 27) {
		if (document.querySelector('.YouTubePopUp-Wrap')) {
			document.querySelector('.YouTubePopUp-Wrap').click()
		}
	}
})
