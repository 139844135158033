<template>
	<header
		:class="['header', { 'box-shadow': shadowActive }]"
		:style="[{ opacity }]"
	>
		<div class="header__wrap">
			<div class="header__underline" :style="underlineStyle" />
			<router-link :to="{ path: '/' }" @click.native="calcUnderline">
				<img :src="$imgUrl('EQ-studio-logotype.svg')" />
			</router-link>
			<nav class="header__menu">
				<router-link
					v-for="link in menu"
					:key="link.id"
					:to="{ path: link.url }"
					class="header__links"
					@click.native="calcUnderline"
				>
					{{ $t(`menu.${link.text}`) }}
				</router-link>
			</nav>
			<socials />
			<!-- Hamburger Menu -->
			<button
				:class="['header__burger', { active: menuActive }]"
				@click="toggleMenu"
			>
				<img
					class="header__burger-e"
					:src="$imgUrl('BURGER-01.svg')"
					alt="E"
				/>
				<img
					class="header__burger-q"
					:src="$imgUrl('BURGER-02.svg')"
					alt="Q"
				/>
			</button>
			<transition name="fade" mode="in-out">
				<nav v-if="menuActive" class="header__menu-mobile">
					<router-link
						v-for="link in mobileMenu"
						:key="link.id"
						:to="{ path: link.url }"
						active-class="header__menu-active"
						exact
						@click.native="closeMenu"
					>
						{{ $t(`menu.${link.text}`) }}
					</router-link>
					<language-switcher />
				</nav>
			</transition>
		</div>
	</header>
</template>

<script>
import Socials from '@/components/socials'
import LanguageSwitcher from '@/components/language-switcher'
import { find, includes, split } from 'lodash'

const defaultUnderline = {
	width: '2rem',
	left: '13.8rem'
}

export default {
	components: {
		Socials,
		LanguageSwitcher
	},
	data: () => ({
		lastScrollTop: 0,
		scrolled: 0,
		opacity: 1,
		shadowActive: false,
		menuActive: false,
		underlineStyle: defaultUnderline,
		underLineTarget: null
	}),
	computed: {
		menu: ({ $store, $i18n }) =>
			$store.getters['settings/menu']($i18n.locale),
		mobileMenu: ({ $store, $i18n }) =>
			$store.getters['settings/mobileMenu']($i18n.locale),
		url: ({ $route }) => $route.path
	},
	mounted() {
		window.addEventListener('scroll', this.scrollFunc)
		window.addEventListener('resize', this.closeMenu)

		// wait for html components to render
		setTimeout(() => {
			this.calcUnderlineByRoute()
		}, 1000)
	},
	watch: {
		$route() {
			this.scrollFunc()
		}
	},
	methods: {
		scrollFunc() {
			this.scrolled = Math.max(
				window.pageYOffset,
				document.documentElement.scrollTop,
				document.body.scrollTop
			)

			if (this.scrolled >= 200) {
				if (this.scrolled > this.lastScrollTop) {
					this.opacity = 0
					this.shadowActive = this.menuActive
				} else {
					this.opacity = 1
					this.shadowActive = !this.menuActive
				}
			} else {
				this.opacity = 1
				this.shadowActive = this.menuActive ? false : this.url !== '/'
			}
			this.lastScrollTop = this.scrolled <= 0 ? 0 : this.scrolled
		},
		openMenu() {
			this.menuActive = true
		},
		closeMenu() {
			this.menuActive = false
			this.calcUnderlineByRoute()
		},
		toggleMenu() {
			if (!this.menuActive) {
				this.openMenu()
			} else {
				this.closeMenu()
			}
		},
		calcUnderline($event) {
			if ($event) {
				if (this.url === '/') {
					this.underLineTarget = null
					this.underlineStyle = defaultUnderline
				} else {
					this.underLineTarget = $event.target
					this.underlineStyle = {
						width:
							$event.target.getBoundingClientRect().width + 'px',
						left: $event.target.getBoundingClientRect().left + 'px'
					}
				}
			} else {
				if (!this.underLineTarget) {
					this.underlineStyle = defaultUnderline
				} else {
					this.underlineStyle = {
						width:
							this.underLineTarget.getBoundingClientRect().width +
							'px',
						left:
							this.underLineTarget.getBoundingClientRect().left +
							'px'
					}
				}
			}
		},
		calcUnderlineByRoute() {
			this.defineUnderlineTarget()
			this.calcUnderline()
		},
		defineUnderlineTarget() {
			if (this.url !== '/') {
				this.underLineTarget = find(
					document.querySelectorAll('.header__links'),
					(link) => {
						if (
							link.getAttribute('href') === this.url ||
							includes(
								split(this.url, '/'),
								link.getAttribute('href').substring(1)
							)
						) {
							return link
						}
					}
				)
			} else {
				this.underLineTarget = null
				this.underlineStyle = defaultUnderline
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollFunc)
		window.removeEventListener('resize', this.closeMenu)
	}
}
</script>
