<template>
	<article class="home__wrap">
		<video preload="true" autoplay muted loop playsinline class="home__video">
			<source
				:src="
					$imgUrl('EQ-video-home-raw-material-rocks-river-nature-architecture.mp4')
				"
				type="video/mp4"
			/>
			Your browser does not support HTML5 video.
		</video>
		<img class="home__logo" :src="$imgUrl('EQ-SYGNET.svg')" />
	</article>
</template>

<script>
export default {}
</script>
