<template>
	<footer class="footer__wrap">
		<div class="footer__image">
			<img :src="$imgUrl('eq-logotyp.png')" />
			<template v-if="isCertVisible">
				<img
					:src="$imgUrl('contact/certyfikat_PHI_1.jpg')"
					style="margin-right: 1rem;"
				/>
				<!-- <img
					:src="$imgUrl('contact/leed-green.jpg')"
					style="margin-right: 1rem;"
				/>
				<img
					:src="$imgUrl('contact/leed-ap.jpg')"
					style="margin-right: 1rem;"
				/> -->
			</template>
		</div>
		<div class="footer__copy">
			<p>{{ $t('footer.p1') }}</p>
			<p>
				{{ $t('footer.p2') }}
				<a :href="$t('external.cud')">
					{{ $t('footer.credits') }}
				</a>
			</p>
		</div>
	</footer>
</template>
<script>
import { includes } from 'lodash'
export default {
	computed: {
		isCertVisible: ({ $route }) =>
			!includes(['contact', 'contact-en'], $route.name)
	}
}
</script>
