<template>
	<article class="about">
		<div class="about__image">
			<transition name="fade" mode="out-in">
				<img
					:src="$imgUrl(`about/${aboutArray[sliderIndex].image}`)"
					alt="image"
					:key="aboutArray[sliderIndex].image"
				/>
			</transition>
		</div>

		<div class="about__divider" />
		<div class="about__slider">
			<slider
				animation="fade"
				:autoplay="false"
				:touch="false"
				v-model="sliderIndex"
				@next="changeIndex"
				@previous="changeIndex"
			>
				<slider-item v-for="item in aboutArray" :key="item.image">
					<div class="slider__item">
						<div
							class="slider__image"
							:style="{
								'background-image': `url(${$imgUrl(
									`about/${aboutArray[sliderIndex].image}`
								)})`
							}"
							:key="aboutArray[sliderIndex].image"
						/>
						<p
							v-for="(text, i) in item.texts"
							class="slider__paragraph"
							:key="i + item.image"
						>
							{{ $t(`about.slider.${sliderIndex}.text.${text}`) }}
						</p>
					</div>
				</slider-item>
			</slider>
			<div class="slider__title">
				<transition name="fade" mode="out-in">
					<h3 class="slider__heading" :key="sliderIndex">
						{{ $t(`about.slider.${sliderIndex}.title`) }}
					</h3>
				</transition>
			</div>

			<transition name="fade" mode="out-in">
				<div v-if="sliderIndex === 1" class="about__image-logo">
					<img :src="$imgUrl(`about/EQsygnet.svg`)" alt="image" />
				</div>
			</transition>
		</div>
	</article>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'

export default {
	components: {
		Slider,
		SliderItem
	},
	data: () => ({
		aboutArray: [
			{
				id: 0,
				image: 'IMG_3593a-bg.jpg',
				texts: [0, 1]
			},
			{
				id: 1,
				image: 'IMG-01-forrest-architecture-trees-mood-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 2,
				image:
					'IMG-03-sand-architecture-dessert-dunes-mood-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 3,
				image:
					'IMG-02-seaside-architecture-see-mood-water-iceland-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 4,
				image: 'IMG_05.jpg',
				texts: [0, 1]
			}
		],
		sliderIndex: 0
	}),
	methods: {
		changeIndex({ next }) {
			this.sliderIndex = next
		}
	}
}
</script>
