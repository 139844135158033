<template>
	<article></article>
</template>
<script>
export default {
	created() {
		this.$router.replace({ path: '/' })
	}
}
</script>
