<template>
	<div class="project-filter">
		<button
			v-for="item in itemTypes"
			:key="item"
			:class="['project-filter__item', { active: item === itemType }]"
			@click="selectButton(item, $event)"
		>
			{{ $t(`projects.${item}`) }}
		</button>
		<div class="project-filter__underline" :style="underlineStyle" />
	</div>
</template>

<script>
import { find } from 'lodash'

export default {
	props: {
		itemType: {
			type: String,
			default: null
		},
		itemTypes: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		underLineTarget: null,
		underlineStyle: {
			width: '0',
			left: '0'
		}
	}),
	mounted() {
		window.addEventListener('resize', this.findUnderline)

		setTimeout(() => {
			this.findUnderline()
		}, 1000)
	},
	watch: {
		itemType() {
			setTimeout(() => {
				this.findUnderline()
			}, 100)
		}
	},
	methods: {
		selectButton(item, $event) {
			this.$emit('change', item)
			this.calcUnderline($event)
		},
		findUnderline() {
			this.defineUnderlineTarget()
			this.calcUnderline()
		},
		defineUnderlineTarget() {
			this.underLineTarget = find(
				document.querySelectorAll('.project-filter__item'),
				(item) => {
					if (item.classList.contains('active')) {
						return item
					}
				}
			)
		},
		calcUnderline($event) {
			if ($event) {
				this.underLineTarget = $event.target
				this.underlineStyle = {
					width: $event.target.getBoundingClientRect().width + 'px',
					left: $event.target.getBoundingClientRect().left + 'px'
				}
			} else {
				this.underlineStyle = {
					width: this.underLineTarget.getBoundingClientRect().width + 'px',
					left: this.underLineTarget.getBoundingClientRect().left + 'px'
				}
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.findUnderline)
	}
}
</script>
