var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"study"},[_c('div',{staticClass:"study__projects"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.project)?_c('h2',{key:_vm.project.type,staticClass:"study__heading"},[_vm._v(" "+_vm._s(_vm.$t(`study.${_vm.project.type}.title`))+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.project)?_c('div',{key:_vm.project.type,staticClass:"study__projects-scroll"},[_c('div',{staticClass:"study__projects-wrap"},_vm._l((_vm.project.assets),function(asset,idx){return _c('div',{key:asset.cover,class:['cover', { 'cover-video': asset.link }],style:({
							'background-image': `url(${_vm.$imgUrl(
								`study/${_vm.project.type}/${asset.id}/${asset.cover}`
							)})`
						}),on:{"click":function($event){return _vm.onClick(asset, idx)},"mouseover":function($event){_vm.imageHover = asset},"mouseleave":function($event){_vm.imageHover = null}}})}),0)]):_vm._e()])],1),_c('filter-buttons',{staticClass:"study__filters",attrs:{"translateKey":"study","project":_vm.project,"projects":_vm.projects},on:{"set-project":_vm.setProject}}),_c('div',{staticClass:"study__info"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.project || _vm.imageHover)?_c('div',{staticClass:"study__info-cover"},[_c('div',{staticClass:"image",style:({
						'background-image': `url(${_vm.$imgUrl(_vm.image)})`
					})}),(_vm.author)?_c('div',{staticClass:"study__info-cover__text"},[_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t(`study.workshop.${_vm.imageHover.id}.title`))+" ")])]),_c('div',[_vm._v(_vm._s(_vm.author))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t(`study.workshop.${_vm.imageHover.id}.year`))+" ")])]):_vm._e()]):_c('study-text',{key:_vm.id,attrs:{"project":_vm.project}})],1)],1),_c('div',{class:['study__info-mobile', { active: _vm.textVisible }]},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.project)?_c('img',{key:_vm.image,attrs:{"src":_vm.$imgUrl(`study/IMG-nature.jpg`),"alt":"image"}}):(_vm.textVisible)?_c('study-text',{attrs:{"project":_vm.project}}):_vm._e()],1)],1),(_vm.project)?_c('div',{staticClass:"study__switch",on:{"click":function($event){_vm.textVisible = !_vm.textVisible}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('img',{key:_vm.textVisible,attrs:{"src":_vm.$imgUrl(_vm.switchImage),"alt":"switch"}})])],1):_vm._e(),_c('Tinybox',{attrs:{"images":_vm.tinyImages,"no-thumbs":""},on:{"close":function($event){_vm.index = null}},model:{value:(_vm.tinyIndex),callback:function ($$v) {_vm.tinyIndex=$$v},expression:"tinyIndex"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }