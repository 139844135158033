// store/menu.js
import { reject } from 'lodash'

export default {
	namespaced: true,
	state: {
		menuItems: [
			{
				id: 0,
				text: 'home',
				urls: {
					pl: '/',
					en: '/'
				}
			},
			{
				id: 1,
				text: 'projects',
				urls: {
					pl: '/projekty',
					en: '/projects'
				}
			},
			{
				id: 2,
				text: 'study',
				urls: {
					pl: '/nauka',
					en: '/study'
				}
			},
			{
				id: 3,
				text: 'about',
				urls: {
					pl: '/o-mnie',
					en: '/about'
				}
			},
			{
				id: 4,
				text: 'contact',
				urls: {
					pl: '/kontakt',
					en: '/contact'
				}
			}
		],
		project: null
	},
	getters: {
		menu: (state) => (locale) => {
			return reject(state.menuItems, { id: 0 }).map((item) => ({
				...item,
				url: item.urls[locale]
			}))
		},
		mobileMenu: (state) => (locale) => {
			return state.menuItems.map((item) => ({
				...item,
				url: item.urls[locale]
			}))
		},
		project: (state) => state.project
	},
	mutations: {
		setProject: (state, project) => {
			state.project = project
		}
	},
	actions: {
		setProject({ commit }, project) {
			commit('setProject', project)
		}
	}
}
