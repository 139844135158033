import {
	ARCHITECTURE,
	INTERIOR,
	EXHIBITIONS,
	ARCHITECTURE_SLUG,
	INTERIOR_SLUG,
	EXHIBITIONS_SLUG,
	IDEA,
	PROJECTION,
	VISUALISATIONS,
	PHOTOS,
	SINGLE_FAMILY_HOMES,
	PUBLIC_BUILDINGS,
	PRIVATE_INTERIORS,
	PUBLIC_INTERIORS,
	MUSEUM_EXHIBITIONS
} from '@/constants'

export default {
	namespaced: true,
	state: {
		data: [
			{
				id: 0,
				type: ARCHITECTURE,
				slug: ARCHITECTURE_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-mountain-tops-clouds-smoke-mood.jpg',
				projects: [
					{
						id: 0,
						group: SINGLE_FAMILY_HOMES,
						name: 'poznan-dziewinska',
						cover: 'ENDOM_ikona.jpg',
						overlay: 'ENDOM_ikona-multiply.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'SC_1_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'SC_2_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'SC_3_INT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'SC_4_EXT-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SC_5_EXT_N-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'SC_6_EXT_N-small.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'ENDOM-rzut.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'ENDOM-schemat-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'ENDOM-schemat-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'ENDOM-schemat-03.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 1,
						group: SINGLE_FAMILY_HOMES,
						name: 'wschowa',
						cover: 'WSCHOWA_ikona.jpg',
						overlay: 'WSCHOWA_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'I_2_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'I_2_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'I_2_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'I_2_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'I_2_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'I_2_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'I_2_7.jpg',
								type: VISUALISATIONS
							},
							{
								id: 7,
								name: 'I_2_8.jpg',
								type: VISUALISATIONS
							},
							{
								id: 8,
								name: 'I_2_rzut.jpg',
								type: PROJECTION
							},
							{
								id: 9,
								name: 'I_2_schemat.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 2,
						group: SINGLE_FAMILY_HOMES,
						name: 'szczecinek',
						cover: 'SZCZECINEK-ikona.jpg',
						overlay: 'SZCZECINEK-multiply.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'A-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'B-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'C-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'D-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SZCZECINEK_rzuty-parter.jpg',
								type: PROJECTION
							},
							{
								id: 5,
								name: 'SZCZECINEK_rzuty-pietro.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 3,
						group: SINGLE_FAMILY_HOMES,
						name: 'chotylow',
						cover: 'CHOTYLOW_ikona.jpg',
						overlay: 'CHOTYLOW_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'I_4_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'I_4_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'I_4_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'I_4_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'I_4_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'I_4_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'I_4_7.jpg',
								type: VISUALISATIONS
							},
							{
								id: 7,
								name: 'I_4_8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'I_4_9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'I_4_rzut_0.jpg',
								type: PROJECTION
							},
							{
								id: 10,
								name: 'I_4_rzut_1.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 4,
						group: SINGLE_FAMILY_HOMES,
						name: 'domaszkow',
						cover: 'DOMASZKOW-ikona.jpg',
						overlay: 'DOMASZKOW-ikona-multiply.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'F1-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'F2-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'F3-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'F4-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'DOMASZKOW-rzut-parter.jpg',
								type: PROJECTION
							},
							{
								id: 5,
								name: 'DOMASZKOW-rzut-pietro.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 5,
						group: PUBLIC_BUILDINGS,
						name: 'mediolan-sasso',
						cover: 'I_4_Akademik_ikona.jpg',
						overlay: 'I_4_Akademik_ikona_small_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'I_4_Akademik_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'I_4_Akademik_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'I_4_Akademik_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'I_4_Akademik_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'I_4_Akademik_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'I_4_Akademik_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'AKADEMIK_rzut-01.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'AKADEMIK_schematy-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'AKADEMIK_schematy-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'AKADEMIK_schematy-03.jpg',
								type: IDEA
							},
							{
								id: 10,
								name: 'AKADEMIK_schematy-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 6,
						group: PUBLIC_BUILDINGS,
						name: 'mediolan-sforza',
						cover: 'II_2_ikona.jpg',
						overlay: 'II_2_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'II_2_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'II_2_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'II_2_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'II_2_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'II_2_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'II_2_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'mediateca_rzut_2.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 7,
						group: PUBLIC_BUILDINGS,
						name: 'borowiec',
						cover: 'ikona_1.jpg',
						overlay: 'ikona_1-multiply.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'sc_1_EXT_RGB_color_WIP-04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'SC_2_EXT_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'SC_3_INT_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'SC_7_EXT_RGB_color_FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'SC_8_RGB_color-FINAL.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'SC_9_RGB_color-final.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'MARKET_rzut_FINAL-01.jpg',
								type: PROJECTION
							},
							{
								id: 7,
								name: 'MARKET_schematy-01.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'MARKET_schematy-02.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'MARKET_schematy-03.jpg',
								type: IDEA
							},
							{
								id: 10,
								name: 'MARKET_schematy-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 8,
						group: PUBLIC_BUILDINGS,
						name: 'dobrzyca-1',
						cover: 'IKONA_szreniawa-bw.jpg',
						overlay: 'IKONA_szreniawa-multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: '01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: '02.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: '03.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: '04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: '05.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: '1B-final-update-01.jpg',
								type: PROJECTION
							},
							{
								id: 6,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-01.jpg',
								type: IDEA
							},
							{
								id: 7,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-02.jpg',
								type: IDEA
							},
							{
								id: 8,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-03.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'AKSONOMETRIE_MUZEUM_ztekstami-04.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 9,
						group: PUBLIC_BUILDINGS,
						name: 'poznan-koscielna',
						cover: 'kościelna_ikona.jpg',
						overlay: 'kościelna_ikona-multiply.jpg',
						labels: [
							'project',
							{ key: 'authors', values: [0, 1] },
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'wizualizacja_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'wizualizacja_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'wizualizacja_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'zdjęcia_1-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'zdjęcia_2-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'zdjęcia_3-final-01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'Koscielna-rzut-final-02.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 10,
						group: PUBLIC_BUILDINGS,
						name: 'poznan-ulanska',
						cover: 'ULANSKA-ikona.jpg',
						overlay: 'ULANSKA-ikona-multiply.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: '01.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: '02.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: '03.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: '04.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'II_6_1.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'II_6_2.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'II_6_3.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'II_6_4.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'II_6_5.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'II_6_6.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'II_6_7.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: 'II_6_rzut_01.jpg',
								type: PROJECTION
							},
							{
								id: 12,
								name: 'II_6_rzut_02.jpg',
								type: PROJECTION
							}
						]
					},
					{
						id: 11,
						group: PUBLIC_BUILDINGS,
						name: 'poznan-marcinkowskiego',
						cover: 'II_7_ikona.jpg',
						overlay: 'II_7_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'II_6_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'II_6_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'II_6_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'II_6_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'II_6_5.jpg',
								type: VISUALISATIONS
							},
							{
								id: 5,
								name: 'II_6_6.jpg',
								type: VISUALISATIONS
							},
							{
								id: 6,
								name: 'II_6_7.jpg',
								type: VISUALISATIONS
							},
							{
								id: 7,
								name: 'II_6_8.jpg',
								type: VISUALISATIONS
							},
							{
								id: 8,
								name: 'II_6_9.jpg',
								type: IDEA
							},
							{
								id: 9,
								name: 'II_6_10.jpg',
								type: IDEA
							},
							{
								id: 10,
								name: 'II_6_11.jpg',
								type: IDEA
							},
							{
								id: 11,
								name: 'II_6_12.jpg',
								type: IDEA
							},
							{
								id: 12,
								name: 'II_6_13.jpg',
								type: IDEA
							},
							{
								id: 13,
								name: 'II_6_14.jpg',
								type: IDEA
							},
							{
								id: 14,
								name: 'II_6_15.jpg',
								type: IDEA
							}
						]
					},
					{
						id: 12,
						group: PUBLIC_BUILDINGS,
						name: 'murowanagoslina',
						cover: 'II_8_ikona.jpg',
						overlay: 'II_8_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'II_8_01.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'II_8_02.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'II_8_03.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'II_8_04.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'II_8_05.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'II_8_06.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'II_8_07.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 13,
						group: PUBLIC_BUILDINGS,
						name: 'dobrzyca-2',
						cover: 'II_9_2_ikona.jpg',
						overlay: 'II_9_1_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'II_9_1.jpg',
								type: VISUALISATIONS
							},
							{
								id: 1,
								name: 'II_9_2.jpg',
								type: VISUALISATIONS
							},
							{
								id: 2,
								name: 'II_9_3.jpg',
								type: VISUALISATIONS
							},
							{
								id: 3,
								name: 'II_9_4.jpg',
								type: VISUALISATIONS
							},
							{
								id: 4,
								name: 'II_9_5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'II_9_6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'II_9_7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'II_9_8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'II_9_schemat.jpg',
								type: PROJECTION
							},
							{
								id: 9,
								name: 'II_9_rzut_1.jpg',
								type: PROJECTION
							}
						]
					}
				]
			},
			{
				id: 1,
				type: INTERIOR,
				slug: INTERIOR_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-ear-grass-sun-flare-mood.jpg',
				projects: [
					{
						id: 0,
						group: PRIVATE_INTERIORS,
						name: 'poznan-bielniki',
						cover: 'river_ikona.jpg',
						overlay: 'III_1_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0, 1],
						assets: [
							{
								id: 0,
								name: 'III_1_1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'III_1_2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'III_1_3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'III_1_4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'III_1_5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'III_1_6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'III_1_7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'III_1_8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'III_1_9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'III_1_10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'III_1_12.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: 'III_1_13.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: 'III_1_14.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: 'III_1_15.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: 'III_1_16.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: 'III_1_17.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: 'III_1_18.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: 'III_1_19.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: 'III_1_20.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: 'III_1_21.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: 'III_1_22.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: 'III_1_23.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: 'III_1_24.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: 'III_1_25.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: 'III_1_26.jpg',
								type: PHOTOS
							},
							{
								id: 25,
								name: 'III_1_27.jpg',
								type: PHOTOS
							},
							{
								id: 26,
								name: 'III_1_28.jpg',
								type: PHOTOS
							},
							{
								id: 27,
								name: 'III_1_29.jpg',
								type: PHOTOS
							},
							{
								id: 28,
								name: 'III_1_30.jpg',
								type: PHOTOS
							},
							{
								id: 29,
								name: 'III_1_31.jpg',
								type: PHOTOS
							},
							{
								id: 30,
								name: 'III_1_33.jpg',
								type: PHOTOS
							},
							{
								id: 31,
								name: 'III_1_34.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 1,
						group: PRIVATE_INTERIORS,
						name: 'walcz',
						cover: 'wieza_ikona.jpg',
						overlay: 'III_2_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 2,
						group: PRIVATE_INTERIORS,
						name: 'zborowo',
						cover: 'III_3_ikona.jpg',
						overlay: 'III_3_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'III_3_1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'III_3_2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'III_3_3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'III_3_4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'III_3_5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'III_3_6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'III_3_7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'III_3_8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'III_3_9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'III_3_10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'III_3_11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: 'III_3_12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: 'III_3_13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: 'III_3_14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: 'III_3_15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: 'III_3_16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: 'III_3_17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: 'III_3_18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: 'III_3_19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: 'III_3_20.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: 'III_3_21.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: 'III_3_22.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: 'III_3_23.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: 'III_3_24.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: 'III_3_25.jpg',
								type: PHOTOS
							},
							{
								id: 25,
								name: 'III_3_26.jpg',
								type: PHOTOS
							},
							{
								id: 26,
								name: 'III_3_27.jpg',
								type: PHOTOS
							},
							{
								id: 27,
								name: 'III_3_28.jpg',
								type: PHOTOS
							},
							{
								id: 28,
								name: 'III_3_29.jpg',
								type: PHOTOS
							},
							{
								id: 29,
								name: 'III_3_30.jpg',
								type: PHOTOS
							},
							{
								id: 30,
								name: 'III_3_31.jpg',
								type: PHOTOS
							},
							{
								id: 31,
								name: 'III_3_32.jpg',
								type: PHOTOS
							},
							{
								id: 32,
								name: 'III_3_33.jpg',
								type: PHOTOS
							},
							{
								id: 33,
								name: 'III_3_34.jpg',
								type: PHOTOS
							},
							{
								id: 34,
								name: 'III_3_35.jpg',
								type: PHOTOS
							},
							{
								id: 35,
								name: 'III_3_36.jpg',
								type: PHOTOS
							},
							{
								id: 36,
								name: 'III_3_37.jpg',
								type: PHOTOS
							},
							{
								id: 37,
								name: 'III_3_38.jpg',
								type: PHOTOS
							},
							{
								id: 38,
								name: 'III_3_39.jpg',
								type: PHOTOS
							},
							{
								id: 39,
								name: 'III_3_40.jpg',
								type: PHOTOS
							},
							{
								id: 40,
								name: 'III_3_41.jpg',
								type: PHOTOS
							},
							{
								id: 41,
								name: 'III_3_42.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 3,
						group: PRIVATE_INTERIORS,
						name: 'kamionki',
						cover: 'III_4_ikona.jpg',
						overlay: 'III_4_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'III_4_1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'III_4_2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'III_4_3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'III_4_4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'III_4_5A.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'III_4_5B.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'III_4_6.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'III_4_7.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'III_4_8.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'III_4_9.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'III_4_10.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: 'III_4_11.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: 'III_4_12.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: 'III_4_13.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: 'III_4_14.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: 'III_4_15.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: 'III_4_16.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: 'III_4_17.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: 'III_4_18.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: 'III_4_19.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: 'III_4_20.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 4,
						group: PUBLIC_INTERIORS,
						name: 'poznan-ulanska',
						cover: 'IV_4_ikona.jpg',
						overlay: 'IV_4_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'IV_1_5.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'IV_1_6.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'IV_1_7.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'IV_1_8.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'IV_1_9.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'IV_1_10.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'IV_1_11.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'IV_1_12.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'IV_1_13.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'IV_1_14.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'IV_1_15.jpg',
								type: PHOTOS
							}
						]
					}
				]
			},
			{
				id: 2,
				type: EXHIBITIONS,
				slug: EXHIBITIONS_SLUG,
				cover:
					'IMG-nature-raw-architecture-project-design-forrest-fog-smoke-mood.jpg',
				projects: [
					{
						id: 0,
						group: MUSEUM_EXHIBITIONS,
						name: 'szreniawa-1',
						cover: 'nawozenie_ikona.jpg',
						overlay: 'V_1_ikona_1_S.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: '17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: '18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: '19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: '20.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: '21.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: '22.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: '23.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: '24.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: '25.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 1,
						group: MUSEUM_EXHIBITIONS,
						name: 'szreniawa-2',
						cover: 'SZRENIAWA_ikona.jpg',
						overlay: 'V_2_ikona_1_S.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: '1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: '2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: '3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: '4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: '5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: '6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: '7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: '8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: '9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: '10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: '11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: '12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: '13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: '14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: '15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: '16.jpg',
								type: PHOTOS
							},
							{
								id: 16,
								name: '17.jpg',
								type: PHOTOS
							},
							{
								id: 17,
								name: '18.jpg',
								type: PHOTOS
							},
							{
								id: 18,
								name: '19.jpg',
								type: PHOTOS
							},
							{
								id: 19,
								name: '20.jpg',
								type: PHOTOS
							},
							{
								id: 20,
								name: '21.jpg',
								type: PHOTOS
							},
							{
								id: 21,
								name: '22.jpg',
								type: PHOTOS
							},
							{
								id: 22,
								name: '23.jpg',
								type: PHOTOS
							},
							{
								id: 23,
								name: '24.jpg',
								type: PHOTOS
							},
							{
								id: 24,
								name: '25.jpg',
								type: PHOTOS
							},
							{
								id: 25,
								name: '26.jpg',
								type: PHOTOS
							},
							{
								id: 26,
								name: '27.jpg',
								type: PHOTOS
							},
							{
								id: 27,
								name: '28.jpg',
								type: PHOTOS
							}
						]
					},
					{
						id: 2,
						group: MUSEUM_EXHIBITIONS,
						name: 'lewkow',
						cover: 'V_3_ikona.jpg',
						overlay: 'V_3_ikona_multi.jpg',
						labels: [
							'project',
							'investor',
							'place',
							'status',
							'scope',
							'area'
						],
						texts: [0],
						assets: [
							{
								id: 0,
								name: 'V_3_1.jpg',
								type: PHOTOS
							},
							{
								id: 1,
								name: 'V_3_2.jpg',
								type: PHOTOS
							},
							{
								id: 2,
								name: 'V_3_3.jpg',
								type: PHOTOS
							},
							{
								id: 3,
								name: 'V_3_4.jpg',
								type: PHOTOS
							},
							{
								id: 4,
								name: 'V_3_5.jpg',
								type: PHOTOS
							},
							{
								id: 5,
								name: 'V_3_6.jpg',
								type: PHOTOS
							},
							{
								id: 6,
								name: 'V_3_7.jpg',
								type: PHOTOS
							},
							{
								id: 7,
								name: 'V_3_8.jpg',
								type: PHOTOS
							},
							{
								id: 8,
								name: 'V_3_9.jpg',
								type: PHOTOS
							},
							{
								id: 9,
								name: 'V_3_10.jpg',
								type: PHOTOS
							},
							{
								id: 10,
								name: 'V_3_11.jpg',
								type: PHOTOS
							},
							{
								id: 11,
								name: 'V_3_12.jpg',
								type: PHOTOS
							},
							{
								id: 12,
								name: 'V_3_13.jpg',
								type: PHOTOS
							},
							{
								id: 13,
								name: 'V_3_14.jpg',
								type: PHOTOS
							},
							{
								id: 14,
								name: 'V_3_15.jpg',
								type: PHOTOS
							},
							{
								id: 15,
								name: 'V_3_16.jpg',
								type: PHOTOS
							}
						]
					}
				]
			}
		]
	},
	getters: {
		data: (state) => state.data
	},
	mutations: {},
	actions: {}
}
