<template>
	<div class="study-text">
		<div class="study-text__item">
			<div v-for="label in project.labels" :key="label" class="study-text__label">
				<strong>{{ $t(`study.${label}`) }}</strong>
				<span>{{ $t(`study.${project.type}.${label}`) }}</span>
			</div>
		</div>
		<div class="study-text__item">
			<template v-for="text in project.texts">
				<template v-if="isLink(text)">
					<a
						:href="$t(`study.${project.type}.texts.${text.url}`)"
						:key="text.url"
						target="_blank"
					>
						{{ $t(`study.${project.type}.texts.${text.url}`) }}
					</a>
				</template>
				<p v-else :key="text" class="study-text__paragraph">
					{{ $t(`study.${project.type}.texts.${text}`) }}
				</p>
			</template>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash'

export default {
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	methods: {
		isLink(item) {
			return get(item, 'type', null) === 'link'
		}
	}
}
</script>
