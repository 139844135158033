import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n, detectLanguageFromUrl } from '@/plugins/i18n'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {
	const detectedLang = detectLanguageFromUrl(to.path)

	if (detectedLang !== i18n.locale) {
		i18n.locale = detectedLang
		localStorage.setItem('language', detectedLang)
	}

	next()
})

export default router
