<template>
	<div class="social-container">
		<language-switcher />
		<a :href="$t('external.ig')" class="icon-instagram" target="_blank" />
	</div>
</template>

<script>
import LanguageSwitcher from '@/components/language-switcher'

export default {
	components: {
		LanguageSwitcher
	}
}
</script>
