<template>
	<div class="language-switcher">
		<button
			@click="switchLanguage('pl')"
			:class="{ active: currentLanguage === 'pl' }"
			:disabled="isChanging"
		>
			PL
		</button>
		<button
			@click="switchLanguage('en')"
			:class="{ active: currentLanguage === 'en' }"
			:disabled="isChanging"
		>
			EN
		</button>
	</div>
</template>

<script>
import { changeLanguage } from '@/plugins/i18n'

export default {
	name: 'LanguageSwitcher',
	data() {
		return {
			isChanging: false
		}
	},
	computed: {
		currentLanguage: ({ $i18n }) => $i18n.locale
	},
	methods: {
		async switchLanguage(lang) {
			if (this.isChanging || this.currentLanguage === lang) return

			this.isChanging = true

			try {
				const newPath = await changeLanguage(lang)
				if (newPath) {
					await this.$router.replace({ path: newPath })
				}
			} finally {
				this.isChanging = false
			}
		}
	}
}
</script>
