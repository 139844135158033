var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"projects"},[_c('div',{staticClass:"projects__projects"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.project)?_c('div',{key:_vm.project.type,staticClass:"projects__projects-scroll"},_vm._l((_vm.groupedProjects),function(group){return _c('div',{key:group.name,staticClass:"projects__group"},[_c('h2',{staticClass:"projects__heading"},[_vm._v(" "+_vm._s(_vm.$t(`projects.groups.${group.name}`))+" ")]),_c('div',{staticClass:"projects__projects-wrap"},_vm._l((group.projects),function(item){return _c('router-link',{key:item.name,staticClass:"cover",style:({
								'background-image': `url(${_vm.$imgUrl(
									`projects/${_vm.project.type}/${item.name}/${item.cover}`
								)})`
							}),attrs:{"tag":"div","to":{
								path: _vm.$localePath(
									'projects',
									`${_vm.project.slug}/${item.name}`
								)
							}},nativeOn:{"mouseover":function($event){_vm.imageHover = item},"mouseleave":function($event){_vm.imageHover = null}}},[_c('div',{staticClass:"cover__overlay",style:({
									'background-image': `url(${_vm.$imgUrl(
										`projects/${_vm.project.type}/${item.name}/${item.overlay}`
									)})`
								})})])}),1)])}),0):_vm._e()])],1),_c('filter-buttons',{staticClass:"projects__filters",attrs:{"translateKey":"projects","project":_vm.project,"projects":_vm.projects},on:{"set-project":_vm.setProject}}),_c('div',{staticClass:"projects__info"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.imageHover)?_c('div',{staticClass:"projects__info-cover"},[_c('div',{staticClass:"image",style:({
						'background-image': `url(${_vm.$imgUrl(_vm.image)})`
					})})]):_c('div',{key:_vm.id,staticClass:"projects__info-cover"},[_c('div',{staticClass:"image",style:({
						'background-image': `url(${_vm.$imgUrl(
							`projects/${_vm.project.type}/${_vm.project.cover}`
						)})`
					})})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }