import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from './pl.json'
import en from './en.json'
import { functions } from './functions'

Vue.use(VueI18n)

export const routesMap = {
	pl: {
		'': '',
		projekty: 'projects',
		nauka: 'study',
		'o-mnie': 'about',
		kontakt: 'contact',
		architektura: 'architecture',
		wnetrza: 'interiors',
		wystawy: 'exhibitions'
	},
	en: {
		'': '',
		projects: 'projekty',
		study: 'nauka',
		about: 'o-mnie',
		contact: 'kontakt',
		architecture: 'architektura',
		interiors: 'wnetrza',
		exhibitions: 'wystawy'
	}
}

export const translatePath = (path, fromLang, toLang) => {
	if (fromLang === toLang) return path

	const segments = path.split('/').filter(Boolean)
	if (segments.length === 0) return '/'

	const translatedSegments = segments.map((segment) => {
		return routesMap[fromLang][segment] || segment
	})

	return '/' + translatedSegments.join('/')
}

export const i18n = new VueI18n({
	locale: localStorage.getItem('language') || 'pl',
	fallbackLocale: 'pl',
	messages: {
		pl,
		en
	},
	postTranslation: (str) => functions.removeOrphans(str)
})

export const changeLanguage = async (lang) => {
	const currentPath = window.location.pathname
	const currentLang = i18n.locale

	if (currentLang === lang) {
		return null
	}

	i18n.locale = lang

	localStorage.setItem('language', lang)

	const newPath = translatePath(currentPath, currentLang, lang)
	return newPath
}

export const detectLanguageFromUrl = (path) => {
	if (path === '/') {
		return i18n.locale
	}

	const segments = path.split('/').filter(Boolean)
	const firstSegment = segments[0]

	for (const [lang, routes] of Object.entries(routesMap)) {
		if (Object.keys(routes).includes(firstSegment)) {
			return lang
		}
	}

	return i18n.locale
}
