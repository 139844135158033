export const functions = {
	imgUrl: (pic) => require('@/assets/img/' + pic),

	firstBold(text) {
		const array = text.trim().split(' ')
		array.splice(0, 1, '<strong>' + array[0] + '</strong>')
		return array.join(' ')
	},
	removeOrphans: (str) => {
		const polishOrphans = [
			'a',
			'i',
			'o',
			'u',
			'w',
			'z',
			'A',
			'I',
			'O',
			'U',
			'W',
			'Z'
		]
		const englishOrphans = [
			'of',
			'at',
			'as',
			'it',
			'an',
			'to',
			'in',
			'In',
			'on',
			'be',
			'is',
			'by'
		]
		const numberOrphans = ['11']

		const lettersToReplace = [
			...polishOrphans,
			...englishOrphans,
			...numberOrphans
		]

		const textReplaced = str.split(' ').map((i) => {
			if (lettersToReplace.includes(i)) {
				return i + '\xa0'
			}
			return i + ' '
		})
		return textReplaced.join('')
	}
}
