import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import settings from './settings'
import study from './study'
import projects from './projects'

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		settings,
		study,
		projects
	}
})
