<template>
	<article class="projects">
		<div class="projects__projects">
			<transition name="fade" mode="out-in">
				<div
					class="projects__projects-scroll"
					v-if="project"
					:key="project.type"
				>
					<div
						v-for="group in groupedProjects"
						:key="group.name"
						class="projects__group"
					>
						<h2 class="projects__heading">
							{{ $t(`projects.groups.${group.name}`) }}
						</h2>
						<div class="projects__projects-wrap">
							<router-link
								tag="div"
								class="cover"
								v-for="item in group.projects"
								:key="item.name"
								:style="{
									'background-image': `url(${$imgUrl(
										`projects/${project.type}/${item.name}/${item.cover}`
									)})`
								}"
								:to="{
									path: $localePath(
										'projects',
										`${project.slug}/${item.name}`
									)
								}"
								@mouseover.native="imageHover = item"
								@mouseleave.native="imageHover = null"
							>
								<div
									class="cover__overlay"
									:style="{
										'background-image': `url(${$imgUrl(
											`projects/${project.type}/${item.name}/${item.overlay}`
										)})`
									}"
								/>
							</router-link>
						</div>
					</div>
				</div>
			</transition>
		</div>

		<filter-buttons
			class="projects__filters"
			translateKey="projects"
			:project="project"
			:projects="projects"
			@set-project="setProject"
		/>

		<div class="projects__info">
			<transition name="fade" mode="out-in">
				<div v-if="imageHover" class="projects__info-cover">
					<div
						class="image"
						:style="{
							'background-image': `url(${$imgUrl(image)})`
						}"
					/>
				</div>
				<div v-else class="projects__info-cover" :key="id">
					<div
						class="image"
						:style="{
							'background-image': `url(${$imgUrl(
								`projects/${project.type}/${project.cover}`
							)})`
						}"
					/>
				</div>
			</transition>
		</div>
	</article>
</template>

<script>
import { get } from 'lodash'
import FilterButtons from '@/components/filter-buttons'

export default {
	components: { FilterButtons },
	data: () => ({
		project: null,
		imageHover: null,
		textVisible: false
	}),
	computed: {
		id: ({ project }) => get(project, 'id', null),
		projects: ({ $store }) => $store.getters['projects/data'],
		image: ({ project, imageHover }) =>
			`projects/${project.type}/${imageHover.name}/big/${imageHover.cover}`,
		groupedProjects() {
			if (!this.project || !this.project.projects) return []

			const groups = this.project.projects.reduce((acc, project) => {
				if (!acc[project.group]) {
					acc[project.group] = {
						name: project.group,
						projects: []
					}
				}
				acc[project.group].projects.push(project)
				return acc
			}, {})

			return Object.values(groups)
		}
	},
	created() {
		if (this.$store.getters['settings/project']) {
			this.setProject(this.$store.getters['settings/project'])
			this.$store.dispatch('settings/setProject', null)
		} else {
			this.setProject(this.projects[0])
		}
	},
	methods: {
		setProject(project) {
			this.project = project
			this.textVisible = false
		}
	}
}
</script>
