<template>
	<div>
		<button
			v-for="item in projects"
			ref="project"
			:key="item.type"
			:class="[
				'button__secondary',
				{ active: project && project.type === item.type }
			]"
			@click="selectButton(item, $event)"
		>
			{{ $t(`${translateKey}.${item.type}.filter`) }}
		</button>
		<div v-if="borderStyle" class="study__filters-border" :style="borderStyle" />
	</div>
</template>

<script>
import { find } from 'lodash'

export default {
	props: {
		project: {
			type: Object,
			default: null
		},
		projects: {
			type: Array,
			required: true
		},
		translateKey: {
			type: String,
			required: true
		}
	},
	data: () => ({
		borderStyle: {
			bottom: '150%',
			height: '100px',
			width: '50px'
		},
		borderParent: null,
		borderTarget: null
	}),
	mounted() {
		window.addEventListener('resize', this.calcBorder)

		if (this.project) {
			this.selectButton(this.project, {
				target: find(this.$refs.project, (item) => {
					return item.classList.contains('active')
				})
			})
		}
	},
	methods: {
		selectButton(project, { target }) {
			this.$emit('set-project', project)
			this.borderTarget = target
			this.borderParent = target.parentElement
			this.calcBorder()
		},
		calcBorder() {
			if (this.borderTarget && this.borderParent) {
				// wait for transition to finish
				setTimeout(() => {
					const borderRect = this.borderTarget.getBoundingClientRect()
					const parentBottom = this.borderParent.getBoundingClientRect().bottom

					this.borderStyle = {
						bottom: parentBottom - borderRect.bottom + 'px',
						height: borderRect.height + 'px',
						width: borderRect.width + 'px'
					}
				}, 300)
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calcBorder)
	}
}
</script>
